html {
  overflow: hidden;
  overflow-x: hidden;
  }
  ::-webkit-scrollbar {
  width: 0px; 
  }

.ymaps-2-1-78-svg-icon {
  background-image: none !important;
}

.tracking-notice {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
  max-width: 480px;
  min-width: 215px;
  text-align: center;
  background-color: lightgrey;
  padding: 8px;
  border-radius: 8px;
  opacity: .85 ;
}

.ymaps-2-1-79-svg-icon {
  background-image: none !important;
}