.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}
.modal {
    position: fixed;
    background: white;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.3);
    width: 450px;
    padding: 10px 15px;
    bottom: 0;
    left: 0;
    margin: auto;
    right: 0;
    transition-duration: 400ms;
    &.hide {
        bottom: -19%;
        overflow-x: hidden;
    }
}
@media screen and (max-width: 768px) {
    .modal {
        width: 270px;
        &.hide {
            bottom: -30%;
            overflow-x: hidden;
        }
    }
}

td:nth-of-type(2) {
    text-align: right;
    width: 100%;
    word-break: break-word;
}
td:nth-of-type(1) {
    color: gray;
}
